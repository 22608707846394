<template>
  <SharedResponseAlert :response="response" />
  <v-row justify="center">
    <v-dialog v-model="showDialog" persistent :width="680">
      <v-card class="rounded-xl ">
        <v-card-title
          class="font-weight-bold text-xl black-dark-color mb-3 px-7 pt-7"
        >
          تقييم المرشد
        </v-card-title>
        <div>
        <SessionsRateStepper
          :step="step"
          @change-step="handleChangeStep"
        ></SessionsRateStepper>
      </div>
        <div v-if="step == 0" class="px-7">
          <p>شعرت بالاحترام والتقدير لمشاعري وأفكاري في أثناء الجلسة ؟</p>
          <SessionsEmojiRate
        
            @change="handleRateCoachRespect"
          ></SessionsEmojiRate>
          
          <p>
            اقترح علي المرشد التواصل خارج الموقع على سبيل المثال التواصل عن
            طريق (رقم الجوال/ انستغرام/ سناب تشات…إلخ)
          </p>
          <v-radio-group inline v-model="form.coach_requested_external_communication">
            <v-radio label="نعم" value="yes" :class="{ 'text-primary': form.coach_requested_external_communication === 'yes' }"> </v-radio>
            <v-radio label="لا" value="no" :class="{ 'text-primary': form.coach_requested_external_communication === 'no' }"> </v-radio>
          </v-radio-group>
          <p>تم حضور المرشد في الوقت المحدد ؟</p>
          <v-radio-group inline v-model="form.coach_attendance">
            <v-radio label="نعم" value="yes" :class="{ 'text-primary': form.coach_attendance === 'yes' }"> </v-radio>
            <v-radio label="لا" value="no" :class="{ 'text-primary': form.coach_attendance === 'no' }"> </v-radio>
          </v-radio-group>
        </div>
        <div v-if="step == 1" class="px-7">
          <p>كانت الجلسة احترافية، وفي بيئة مناسبة وهادئة ؟</p>
          <SessionsEmojiRate
            @change="handleRateCoachProfessionalism"
          ></SessionsEmojiRate>
          <p>
            قسم المرشد الوقت كما يلزم بما يضمن الاستفادة من الجلسة بأقصى حد ؟
          </p>
          <SessionsEmojiRate
            @change="handleRateCoachTimeManagement"
          ></SessionsEmojiRate>
          <p>شعرت أن المرشد فهم مشاعري ومشكلتي بشكل صحيح ؟</p>
          <SessionsEmojiRate
            @change="handleRateCoachUnderstanding"
          ></SessionsEmojiRate>
        </div>

        <div v-if="step == 2" class="px-7">
          <p>المرشد قدم لي حلولاً مناسبة لمشكلتي</p>
          <SessionsEmojiRate
            @change="handleRateCoachSolutions"
          ></SessionsEmojiRate>
          <v-row>
            <v-col cols="12">
              <label class="field-label">نص التقييم</label>

              <v-textarea
                v-model="form.comment"
                :error="v$.comment.$errors.length > 0"
                :error-messages="v$.comment.$errors.map((e) => e.$message)"
                @change="v$.comment.$touch"
                @blur="v$.comment.$touch"
                density="compact"
                variant="solo"
                no-resize
                rows="4"
                class="text-lg"
              ></v-textarea>
            </v-col>
            <v-col cols="12">
              <div>
                <v-rating
                  v-model="form.rate"
                  half-increments
                  size="18"
                  density="comfortable"
                  class="ma-2"
                  color="grey"
                  active-color="yellow-darken-3"
                ></v-rating>
                <span class="font-weight-bold">{{ form.rate.toFixed(1) }}</span>
              </div>
              <div
                v-if="v$.rate.$errors.length"
                class="v-messages__message text-error text-xxs"
              >
              {{ v$.rate.$errors[0].$message }}
              </div>
            </v-col>
          </v-row>
        </div>
        <div class="px-7">
        <v-btn
          @click="nextStep"
          size="large"
          height="56"
          width="170"
          class="my-7 rounded-lg"
          :block="smAndDown"
          :loading="sessionStore.btnLoading"
          
        >
          التالي
        </v-btn>
      </div>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script setup>
import { useDisplay } from 'vuetify';
import { useSessionStore } from '~~/stores/SessionStore';
import { required$, minLength$, maxLength$ } from '@/helpers/validators';
import { helpers } from '@vuelidate/validators';

const sessionStore = useSessionStore();

const emit = defineEmits(['close']);

const props = defineProps({
  show: {
    type: Boolean,
    default: false,
  },
});
const serverErrors = ref({});
const form = ref({
  rate: 0,
  comment: '',
  coach_respect: 'strongly_agree',
  coach_requested_external_communication: 'yes',
  coach_attendance: 'yes',
  coach_professionalism: 'strongly_agree',
  coach_time_management: 'strongly_agree',
  coach_understanding: 'strongly_agree',
  coach_solutions: 'strongly_agree',
});
const rateNum = helpers.regex( /^(0*[1-9][0-9]*(\.[0-9]+)?|0+\.[0-9]*[1-9][0-9]*)$/  );
const digitNumber = helpers.regex(/^[0-9]*$/);

const rules = {
  rate: {
    required: helpers.withMessage(
        'هذا الحقل مطلوب',
        rateNum
      ),
    number: helpers.withMessage(
        'يجب عليك اختيار عدد صحيح',
        digitNumber
      ),
  },
  comment: {
    required$,
    minLength$: minLength$(3),
    maxLength$: maxLength$(255),
  },
};
const { v$ } = useCustomVulidate(rules, form.value, serverErrors.value);

const response = computed(() => {
  return sessionStore.responseData;
});

watch(
  () => props.show,
  (newValue) => {
    showDialog.value = newValue;
    if (!newValue) {
      form.value = {
        rate: 1,
        comment: '',
      };
    }
  },
  { deep: true }
);

const showDialog = ref(props.show);

const { smAndDown } = useDisplay();

async function rateSession() {
  v$.value.$validate();
  if (!v$.value.$error) {
    await sessionStore
      .rateSession({
        ...form.value,
      })
      .then((success) => {
        if (success) {
          setTimeout(() => {
            emit('close');
          }, 3000);
        }
      });
  }
}
const step = ref(0);
function nextStep() {
  if (step.value < 2) {
    step.value = step.value + 1;
  } else {
    rateSession();
  }
}
function handleChangeStep(newStep) {
  step.value = newStep;
}
function handleRateCoachProfessionalism(rate) {
  form.value.coach_professionalism = rate;
}
function handleRateCoachTimeManagement(rate) {
  form.value.coach_time_management = rate;
}
function handleRateCoachUnderstanding(rate) {
  form.value.coach_understanding = rate;
}
function handleRateCoachSolutions(rate) {
  form.value.coach_solutions = rate;
}
function handleRateCoachRespect(rate) {
  form.value.coach_respect = rate;
}
</script>