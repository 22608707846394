<template>
  <v-row class="mt-3 mb-5 flex-nowrap">
    <div
      class="w-18 emoji-container py-4 d-flex flex-column justify-center align-center"
      @click="changeSelectedEmoji('strongly_agree')"
      :class="{ active: selectedEmoji === 'strongly_agree' }"
    >
      <img src="~/assets/images/evaluation/strongly-agree.gif" class="w-40" />
      <p class="text-xs text-primary mt-1 text-center">موافق بشدة</p>
    </div>
    <div
      class="w-18 emoji-container mr-2 py-4 d-flex flex-column justify-center align-center"
      @click="changeSelectedEmoji('agree')"
      :class="{ active: selectedEmoji === 'agree', 'w-emojies-no-text': smAndDown }"
    >
      <img src="~/assets/images/evaluation/agree.gif" class="w-40" />
      <p v-if="!smAndDown" class="text-xs text-primary mt-1">موافق</p>
    </div>
    <div
      class="w-18 emoji-container mr-2 py-4 d-flex flex-column justify-center align-center"
      @click="changeSelectedEmoji('neutral')"
      :class="{ active: selectedEmoji === 'neutral', 'w-emojies-no-text': smAndDown }"
    >
      <img src="~/assets/images/evaluation/neutral.gif" class="w-40" />
      <p v-if="!smAndDown" class="text-xs text-primary mt-1">محايد</p>
    </div>
    <div
      class="w-18 emoji-container mr-2 py-4 d-flex flex-column justify-center align-center"
      @click="changeSelectedEmoji('disagree')"
      :class="{ active: selectedEmoji === 'disagree', 'w-emojies-no-text': smAndDown }"
    >
      <img src="~/assets/images/evaluation/disagree.gif" class="w-40" />
      <p v-if="!smAndDown" class="text-xs text-primary mt-1 text-center">غير موافق</p>
    </div>
    <div
      class="w-18 emoji-container mr-2 py-4 d-flex flex-column justify-center align-center"
      @click="changeSelectedEmoji('strongly_disagree')"
      :class="{ active: selectedEmoji === 'strongly_disagree' }"
    >
      <img
        src="~/assets/images/evaluation/strongly-disagree.gif"
        class="w-40"
      />
      <p class="text-xs text-primary text-center">غير موافق بشدة</p>
    </div>
  </v-row>
</template>

<script setup>
import { useDisplay } from 'vuetify';

const { smAndDown } = useDisplay();
const selectedEmoji = ref('strongly_agree');

const emit = defineEmits(['change']);
function changeSelectedEmoji(emoji) {
  selectedEmoji.value = emoji;
  emit('change', emoji);
}
</script>

<style scoped lang="scss">
.emoji-container {
  border-radius: 16px;
  background-color: rgba(130, 195, 192, 0.2);
  img {
    filter: grayscale(80%);
  }
}
.active {
  background-color: #82c3c0 !important;
  p {
    color: #fff !important;
  }
  img {
    filter: grayscale(0%);
  }
}
@media (max-width: 550px) {
  .w-18 {
    width: 30% !important;
  }
  .w-emojies-no-text {
    width: 18% !important;
  }
  .text-xs{
    font-size: 12px !important;
  }
}
</style>
