<template>
  <v-stepper alt-labels class="rate-stepper" v-model="currentStep">
    <v-stepper-header>
      <v-stepper-item value="1">
        <template v-slot:title>
          <span class="text-xxs black-dark-color">الخطوة الأولى</span>
        </template>
      </v-stepper-item>
      <v-divider></v-divider>

      <v-stepper-item value="2">
        <template v-slot:title>
          <span class="text-xxs black-dark-color">الخطوة الثانية</span>
        </template>
      </v-stepper-item>
      <v-divider></v-divider>

      <v-stepper-item value="3">
        <template v-slot:title>
          <span class="text-xxs black-dark-color">الخطوة الثالثة</span>
        </template>
      </v-stepper-item>
    </v-stepper-header>
  </v-stepper>
</template>

<script setup>
const emit = defineEmits(['changeStep']);
const props = defineProps({
  step: {
    type: Number,
    default: 0,
  },})
const currentStep =ref(0)
watch(
  () => props.step,
  (newValue) => {
    currentStep.value = newValue;
  },
  { deep: true }
);
watch(
  () => currentStep.value,
  (newValue) => {
    emit('changeStep', newValue);
  },
  { deep: true }
);
</script>
